import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

// 样式
import styles from "./index.module.less";

// 组件
import { Skeleton, Cell, NoticeBar, Empty } from "react-vant";
import { ClockO, CartO, BalanceO, Completed, Failure } from "@react-vant/icons";
import OrderProduct from "./components/order-product";

// 接口
import { findOrderDetail, selectOrderByCustomer } from "../../api/order";

// 类
import {
  IOrderListItem,
  IOrderStatus,
  IOrderItemProduct,
  IRelevanceList,
} from "../../types/order";

// 枚举
import { ORDER_STATUS, DELIVERS } from "./dict";
import config from "../../config";

// 公共方法
import { replacePrice } from "../../utils";
import { cloneDeep } from "lodash";

const Index: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isEmpty, setIsEmpty] = useState<boolean>(false); // 判断是否搜索不到页面
  const [firstLoad, setFirstLoad] = useState<boolean>(true); // 判断是否首次加载
  const [orderDetail, setOrderDetail] = useState<IOrderListItem>(); // 订单详情
  const [nowStep, setNowStep] = useState<IOrderStatus | null>(null); // 当前的订单状态
  const [addProductList, setAddProductList] = useState<IOrderItemProduct[]>([]); // 当前订单商品
  const [resultPrice, setResultPrice] = useState<string>(""); // 总价
  const [latePrice, setLatePrice] = useState<string>(""); // 待支付金额
  const [backProductList, setBackProductList] = useState<IOrderItemProduct[]>(
    []
  ); // 退货商品
  const [backNum, setBackNum] = useState<number>(0); // 退货商品总数
  const [allNum, setAllNum] = useState<number>(0); // 总商品数
  const [oldOrderPriceList, setOldOrderPriceList] = useState<IRelevanceList[]>(
    []
  ); // 关联订单
  const [oldOrderList, setOldOrderList] = useState<IOrderListItem[]>([]); // 客户赊账单
  const [searchParams] = useSearchParams();
  const uId = searchParams.get("uId") as string; // 订单uId

  // 是否送货
  const deliversName = DELIVERS.find(
    (f) => f.value === orderDetail?.isDelivers
  )?.text;

  /**
   * 渲染组件
   */
  const RenderIcon = () => {
    switch (nowStep?.value) {
      case 10:
        return <ClockO fontSize={40} />;
      case 20:
        return <CartO fontSize={40} />;
      case 30:
        return <BalanceO fontSize={40} />;
      case 40:
        return <Completed fontSize={40} />;
      case 50:
        return <Failure fontSize={40} />;
      default:
        return null;
    }
  };

  /**
   * 获取数据
   */
  const getData = async () => {
    try {
      const res = await findOrderDetail({ uId });
      if (res.status === "ok" && res.data) {
        const orderInfo = res.data;
        let resultPrice = orderInfo
          ? parseFloat(replacePrice(orderInfo.edit_price || orderInfo.price)) +
            parseFloat(replacePrice(orderInfo.other_price || "0")) -
            parseFloat(
              replacePrice(
                orderInfo.status === 30 && orderInfo.pay_money
                  ? orderInfo.pay_money
                  : "0"
              )
            )
          : 0;
        // 待支付金额
        const latePrice = orderInfo
          ? parseFloat(replacePrice(orderInfo.edit_price || orderInfo.price)) +
            parseFloat(replacePrice(orderInfo.other_price || "0")) -
            parseFloat(
              replacePrice(orderInfo.pay_money ? orderInfo.pay_money : "0")
            )
          : 0;
        let oldOrderPriceList: IRelevanceList[] = [];
        let oldOrderList: IOrderListItem[] = [];
        if (orderInfo.customer_uId) {
          const oldOrderResult = await selectOrderByCustomer({
            status: orderInfo.status === 40 ? 40 : 30,
            customer_uId: orderInfo.customer_uId,
            order_uId: orderInfo.uId,
            shop_uId: orderInfo.shop_uId,
          });
          if (oldOrderResult.status === "ok" && oldOrderResult.data) {
            const list: IOrderListItem[] = cloneDeep(oldOrderResult.data);
            // 如果当前订单已经绑定了其它订单了 则无法再继续关联
            oldOrderPriceList = list
              .filter(
                (f) => f.association_uId && f.association_uId === orderInfo.uId
              )
              .map((m) => {
                return {
                  order_no: m.order_no,
                  price: (
                    parseFloat(replacePrice(m.edit_price || m.price)) +
                    parseFloat(replacePrice(m.other_price || "0")) -
                    parseFloat(replacePrice(m.pay_money || "0"))
                  ).toFixed(2),
                };
              });
            oldOrderList = list
              .filter(
                (f) =>
                  (((orderInfo.association_uId &&
                    f.association_uId === orderInfo.uId) ||
                    (!orderInfo.association_uId && !f.association_uId) ||
                    f.association_uId === orderInfo.uId) &&
                    orderInfo.status !== 40) ||
                  (orderInfo.status === 40 &&
                    f.association_uId === orderInfo.uId)
              )
              .map((m) => {
                m.price = (
                  parseFloat(replacePrice(m.edit_price || m.price)) +
                  parseFloat(replacePrice(m.other_price || "0")) -
                  parseFloat(replacePrice(m.pay_money || "0"))
                ).toFixed(2);
                return m;
              });
          }
        }
        // 判断当前订单状态
        const obj = ORDER_STATUS.find((f) => f.value === orderInfo?.status);
        const addProductList: IOrderItemProduct[] = [];
        const backProductList: IOrderItemProduct[] = [];
        let allNum = 0;
        let backNum = 0;
        if (
          orderInfo &&
          Array.isArray(orderInfo.children) &&
          orderInfo.children.length > 0
        ) {
          orderInfo.children = orderInfo.children.map((m) => {
            m.product_img = `${config.FILE_URL}${m.product_img}`;
            // 获取最大可选商品数
            if (m.inventory_uId && m.inventory !== null) {
              m.inventory =
                m.inventory + m.num > 999 ? 999 : m.inventory + m.num;
            }
            if (!m.inventory_uId && m.product_inventory !== null) {
              m.product_inventory =
                m.product_inventory + m.num > 999
                  ? 999
                  : m.product_inventory + m.num;
            }
            m.customer_price_result = m.customer_edit_price
              ? m.customer_edit_price
              : m.price !== m.customer_price
              ? m.customer_price
              : "";
            if (m.isBack === 1) {
              backProductList.push(m);
              backNum += m.num;
            } else {
              addProductList.push(m);
              allNum += m.num;
            }
            return m;
          });
        }
        setAddProductList(addProductList);
        setBackProductList(backProductList);
        setBackNum(backNum);
        setAllNum(allNum);
        setNowStep(obj || null);
        setResultPrice(resultPrice.toFixed(2));
        setLatePrice(latePrice.toFixed(2));
        setOrderDetail(orderInfo);
        setOldOrderPriceList(oldOrderPriceList);
        setOldOrderList(oldOrderList);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
    } finally {
      setFirstLoad(false);
    }
  };

  useEffect(() => {
    document.title = "订单详情";
    if (uId) {
      setFirstLoad(true);
      getData();
    } else {
      setFirstLoad(false);
      setIsEmpty(true);
    }
  }, [location]);

  return (
    <div>
      {firstLoad && (
        <div className="pt-2">
          <Skeleton title />
        </div>
      )}
      {!firstLoad && (
        <>
          {isEmpty && <Empty description="查询不到订单" />}
          {!isEmpty && (
            <div className={styles.order_detail}>
              {/* 顶部状态 */}
              <div
                className={styles.order_step}
                style={{ backgroundColor: nowStep ? nowStep.tag : "" }}
              >
                <RenderIcon />
                <span className="ml-1">
                  {nowStep && nowStep.label}
                  {orderDetail &&
                  orderDetail.status === 30 &&
                  orderDetail.pay_money
                    ? `(已付¥${orderDetail.pay_money})`
                    : ""}
                  {orderDetail &&
                    orderDetail.is_credit &&
                    orderDetail.status === 40 &&
                    "(赊账)"}
                </span>
              </div>
              {/* 当前订单是否已经被关联 */}
              {orderDetail && orderDetail.association_uId && (
                <NoticeBar
                  onClick={() => {
                    navigate(
                      `/order-detail?uId=${orderDetail.association_uId}`,
                      {
                        replace: true,
                      }
                    );
                  }}
                  mode="link"
                  text="当前订单已被关联，点击查看"
                />
              )}
              <div className={styles.order_list_top}>商品详情</div>
              {/* 当前订单商品 */}
              <div className={`p-1 ${styles.order_list}`}>
                <div className={styles.order_list_content}>
                  {addProductList.map((orderItem, orderIndex) => (
                    <OrderProduct
                      key={orderItem.uId}
                      productInfo={orderItem}
                      imageIndex={orderIndex}
                      imageList={addProductList.map((m) => m.product_img)}
                    />
                  ))}
                </div>
                {/* 退货商品 */}
                {backProductList.length > 0 && (
                  <>
                    <div className={styles.order_list_back}>
                      退货商品（总数量：{backNum}）
                    </div>
                    {backProductList.map((orderItem, orderIndex) => (
                      <OrderProduct
                        key={orderItem.uId}
                        productInfo={orderItem}
                        imageIndex={orderIndex}
                        imageList={backProductList.map((m) => m.product_img)}
                      />
                    ))}
                  </>
                )}
                {/* 关联订单信息 */}
                {(oldOrderPriceList.length > 0 ||
                  (orderDetail && orderDetail.pay_money)) && (
                  <>
                    <div className={`${styles.old_order_item} mt-1`}>
                      <div>+ 当前订单</div>
                      {orderDetail && (
                        <div className={`${styles.price_color} ${styles.flex}`}>
                          ¥{orderDetail.edit_price || orderDetail.price}
                          {orderDetail && orderDetail.edit_price && (
                            <div className={styles.order_list_allPrice_o}>
                              ¥{orderDetail.price}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {oldOrderPriceList.map((item) => (
                      <div
                        key={item.order_no}
                        className={`${styles.old_order_item} ${styles.l}`}
                      >
                        <div>+ No.{item.order_no}</div>
                        <div className={styles.price_color}>¥{item.price}</div>
                      </div>
                    ))}
                    {orderDetail && orderDetail.pay_money && (
                      <div className={`${styles.old_order_item} ${styles.l}`}>
                        <div>
                          -{" "}
                          {orderDetail.pay_money_time
                            ? orderDetail.pay_money_time
                            : ""}{" "}
                          已付
                        </div>
                        <div className={styles.price_color}>
                          ¥{orderDetail.pay_money}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {/* 赊账单 */}
                {orderDetail && orderDetail.status === 30 && (
                  <>
                    <div className={styles.order_list_all}>
                      <div>
                        总数量：
                        <span className={styles.order_list_allPrice_a}>
                          {allNum}
                        </span>
                      </div>
                      <div className={styles.order_list_allPrice}>
                        总金额：
                        <div className={styles.order_list_allPrice_a}>
                          ¥{resultPrice}
                        </div>
                      </div>
                    </div>
                    {orderDetail.pay_money && (
                      <div className={`${styles.order_list_allPrice} mt-1`}>
                        累计结欠：
                        <div className={styles.order_list_allPrice_a}>
                          ¥{latePrice}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {/* 已完成单 */}
                {orderDetail && orderDetail.status === 40 && (
                  <>
                    <div className={styles.order_list_all}>
                      <div>
                        总数量：
                        <span className={styles.order_list_allPrice_a}>
                          {allNum}
                        </span>
                      </div>
                      <div className={styles.order_list_allPrice}>
                        总金额：
                        <div className={styles.order_list_allPrice_a}>
                          ¥{resultPrice}
                        </div>
                        {orderDetail.edit_price &&
                          oldOrderPriceList.length <= 0 && (
                            <div className={styles.order_list_allPrice_o}>
                              ¥{orderDetail.price}
                            </div>
                          )}
                      </div>
                    </div>
                    {orderDetail.pay_money && (
                      <div className={`${styles.order_list_allPrice} mt-1`}>
                        累计结欠
                        {latePrice !== "0.00" ? "(已由关联账单支付)" : ""}：
                        <div className={styles.order_list_allPrice_a}>
                          ¥{latePrice}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {/* 待处理 已处理 单 */}
                {orderDetail && ![30, 40].includes(orderDetail.status) && (
                  <div className={styles.order_list_all}>
                    <div>
                      总数量：
                      <span className={styles.order_list_allPrice_a}>
                        {allNum}
                      </span>
                    </div>
                    <div className={styles.order_list_allPrice}>
                      总金额：
                      <div className={styles.order_list_allPrice_a}>
                        ¥{resultPrice}
                      </div>
                      {orderDetail.edit_price && (
                        <div className={styles.order_list_allPrice_o}>
                          ¥{orderDetail.price}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* 客户历史赊账单 */}
              {oldOrderList.length > 0 && (
                <>
                  <div className={styles.order_list_top}>历史赊账单</div>
                  <div className={styles.order_list}>
                    <Cell.Group border={false}>
                      {oldOrderList.map((item, index) => (
                        <Cell
                          key={item.uId}
                          use-label-slot
                          border={index !== oldOrderList.length - 1}
                          title={`No.${item.order_no}`}
                          onClick={() => {
                            navigate(`/order-detail?uId=${item.uId}`);
                          }}
                          label={
                            <div>
                              <div>日期：{item.createdAt}</div>
                              <div>
                                金额：
                                <span className={styles.price_color}>
                                  ¥{item.price}
                                </span>
                              </div>
                            </div>
                          }
                        ></Cell>
                      ))}
                    </Cell.Group>
                  </div>
                </>
              )}
              {/* 订单详情 */}
              <div className={styles.order_list_top}>订单详情</div>
              <div className={styles.order_list}>
                <Cell.Group border={false}>
                  <Cell title="订单编号">No.{orderDetail?.order_no}</Cell>
                  <Cell title="下单时间">{orderDetail?.createdAt}</Cell>
                  <Cell title="客户">
                    {orderDetail?.customer_name || "散客"}
                  </Cell>
                  {orderDetail?.status === 40 && orderDetail?.pay_time && (
                    <Cell title="付款时间">{orderDetail?.pay_time}</Cell>
                  )}
                  {orderDetail?.status === 40 && (
                    <Cell title="支付方式">
                      {orderDetail?.pay_type === 1 ? "线上支付" : "现金支付"}
                    </Cell>
                  )}
                  <Cell title="是否送货">{deliversName}</Cell>
                  {orderDetail?.isDelivers === 1 && (
                    <Cell title="送货地址">{orderDetail?.address_content}</Cell>
                  )}
                </Cell.Group>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Index;
